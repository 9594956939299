<template>
  <div class="app-container" style="text-align: left">
    <el-form label-width="130px">
      <el-form-item :label="$t('miMember.memberNo')" prop="memberNo">
        <el-input size="mini" style="width: 300px" v-model.trim="listQuery.memberNo" @blur="getEmailTail">
        </el-input>
        <span style="color: red">    {{ $t('Please.enter.the.dealer.account') }}</span>
      </el-form-item>
      <el-form-item :label="$t('miMember.email')" prop="email">
        <el-input size="mini" style="width: 300px" v-model.trim="listQuery.email" :readonly="readonly">
        </el-input>
        <span style="color: red">    {{ $t('Please.enter.the.email') }}</span>
      </el-form-item>
      <p style="text-align: center;color: red;font-size: 20px">{{ $t('Login.password.and.payment.password') }}</p>
      <el-form-item>
        <el-button style="margin-left: 40%" :loading="btnLoading" size="medium" type="primary" @click="saveBtn">
          {{ this.$t('button.submit') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {retrievePassword, getMemberEmailSuffix} from "@/api/auth";

export default {
  name: "ForgotPassword",
  data() {
    return {
      btnLoading: false,
      readonly: true,
      listQuery: {
        memberNo: '',
        email: ''
      }
    }
  },
  methods: {
    getEmailTail() {
      this.btnLoading = true
      if (!this.listQuery.memberNo) {
        return
      }
      this.listQuery.memberNo = this.listQuery.memberNo.toUpperCase()
      getMemberEmailSuffix({...this.listQuery}).then(res => {
        this.listQuery.email = res.data
        this.btnLoading = false
        this.readonly = false
      }).catch(err => {
        this.btnLoading = false
        this.readonly = true
        console.error(err)
      })
    },
    saveBtn() {
      this.btnLoading = true
      this.listQuery.memberNo = this.listQuery.memberNo.toUpperCase()
      retrievePassword({...this.listQuery}).then(() => {
        this.$message.success(this.$t('please.go.to.the.email'))
        this.btnLoading = false
        this.$router.push({name: 'Login'})
      }).catch(err => {
        this.btnLoading = false
        console.error(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
